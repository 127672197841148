/* global $ */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["tab", "toggle"];

  // Show the tab with the given key, hide all the others
  switch(event) {
    this.makeToggleActive(event.target)
    this.revealTab(event.params.key)
  }

  makeToggleActive(activeToggle) {
    const activeClass = "active"

    this.toggleTargets.forEach((toggle) => {
      toggle.classList.remove(activeClass)
    })
    activeToggle.classList.add(activeClass)
  }

  revealTab(activeKey) {
    this.tabTargets.forEach((tab) => {
      const tabKey = tab.dataset["tabsKey"]
      if (tabKey === activeKey) {
        tab.classList.remove('d-none')
      } else {
        tab.classList.add('d-none')
      }
    })
  }
}
