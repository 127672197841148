/* global window */
/* global IntersectionObserver */
import { Controller } from '@hotwired/stimulus';

function easeInOutSine(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

function supportsIntersectionObserver() {
  if (typeof (IntersectionObserver) === 'undefined') {
    return false;
  }
  return true;
}

export default class extends Controller {
  animate() {
    window.requestAnimationFrame(this.step.bind(this));
  }

  connect() {
    if (!supportsIntersectionObserver()) { return; }

    this.initializeCallback();
  }

  step(timestamp) {
    if (!this.start) {
      this.start = timestamp;
    }
    const progress = (timestamp - this.start) / this.duration;
    const actualNumber = easeInOutSine(progress) * this.final;

    this.setText(Math.floor(actualNumber));

    if (progress < 1) {
      this.animate();
    } else {
      this.setText(this.final);
    }
  }

  initializeCallback() {
    this.startObserving();
    this.duration = 4000; // duration in ms
    this.final = parseInt(this.element.textContent, 10); // parse out the final number
  }

  animateAndStopObserving(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.animate();
        this.stopObserving();
      }
    });
  }

  setText(text) {
    this.element.textContent = text;
  }

  startObserving() {
    this.observer = new IntersectionObserver(this.animateAndStopObserving.bind(this));
    this.observer.observe(this.element);
  }

  stopObserving() {
    this.observer.unobserve(this.element);
  }
}
