import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'categoryInput',
    'link',
    'selectLabel',
  ]

  connect () {
    if (this.categoryInputTarget.dataset.categoryName != undefined) {
      this.updateSelectLabelFromElement(this.categoryInputTarget)
    }
  }

  updateCategory (event) {
    this.updateCategoryFromElement(event.target)
    this.updateSelectLabelFromElement(event.target)
  }

  updateCategoryValueFromData () {
    if (this.categoryInputTarget.value) {
      return
    }

    this.categoryInputTarget.value = this.categoryInputTarget.dataset.categoryId
  }

  updateCategoryFromElement (element) {
    this.categoryInputTarget.value = element.dataset.categoryId
    this.categoryInputTarget.dataset.categoryName = element.dataset.categoryName
    this.categoryInputTarget.dataset.categoryId = element.dataset.categoryId
  }

  updateSelectLabelFromElement (element) {
    this.selectLabelTarget.textContent = element.dataset.categoryName
  }
}
