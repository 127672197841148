// Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// JS libraries
import "../scripts/frontend/setup_jquery";
import "../scripts/frontend/setup_translations";
import 'bootstrap';

const application = Application.start();
import AnimateTextController from '../scripts/controllers/animate_text_controller';
import CategoryDropdown from '../scripts/controllers/category_dropdown_controller';
import CounterController from '../scripts/controllers/counter_controller';
import FilterController from '../scripts/controllers/filter_controller';
import TabsController from '../scripts/controllers/tabs_controller';

application.register("animate-text", AnimateTextController);
application.register("category-dropdown", CategoryDropdown);
application.register("counter", CounterController);
application.register("filter", FilterController);
application.register("tabs", TabsController);
