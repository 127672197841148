import { Controller } from '@hotwired/stimulus';
import Typed from 'typed.js';

export default class extends Controller {
  static values = {
    items: Array,
  }

  connect() {
    this.textElement().innerHTML = ""
    const options = this.optionsForTyped()
    this.typed = new Typed(this.textElement(), options)
  }

  disconnect() {
    if (this.typed && this.typed.destroy) {
      this.typed.destroy()
    }
  }

  // Returns the options for the Typed.js instance.
  optionsForTyped() {
    return {
      backDelay: 2000,
      backSpeed: 10,
      loop: true,
      startDelay: 500,
      strings: this.itemsValue,
      typeSpeed: 50,
    }
  }

  // Returns the first strong element. That's the element we want to animate.
  textElement() {
    return this.element.querySelector('strong')
  }
}
